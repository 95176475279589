<template>
  <div class="page-download">
    <main>
      <section class="section a">
        <img class="img" :src="require('@/assets/download/symbol_app_03.png')" alt="">
        <div class="right">
          <img class="img" :src="require('@/assets/download/symbol_app_04.png')" alt="">
          <div class="bottom">
            <div class="btn a" @click="handleDownload(link)">客户端</div>
            <div class="btn b" @click="popupshow=true">相关下载></div>
          </div>
        </div>
      </section>

      <!-- <section class="section b">
        <img class="img-title" :src="require('@/assets/download/title_app_01.png')" alt="">
        <div class="icon-box">
          <div class="item" v-for="(A,a) in icons" :key="a">
            <div class="icon">
              <i class="zamerIcons" :class="A.icon"></i>
            </div>
            <span>{{A.text}}</span>
          </div>
        </div>
      </section> -->

      <section class="section c" v-if="popupshow">
        <div class="popup">
          <div class="btn-close" @click="popupshow=false"></div>
          <div class="content">

            <!-- <div class="title a">软件名称</div>
            <div class="title b">版本</div>
            <div class="title c">大小</div>
            <div class="title d">更新时间</div>
            <div class="title e">下载</div>

            <template v-for="(A,a) in links">
              <div class="item a" :key="$randomKey(a)" v-html="A.name"></div>
              <div class="item b" :key="$randomKey(a)">{{A.version}}</div>
              <div class="item c" :key="$randomKey(a)">{{A.size}}</div>
              <div class="item d" :key="$randomKey(a)">{{A.update}}</div>
              <div class="item e" :key="$randomKey(a)">下载</div>
            </template> -->

            <table class="down-table">
              <thead>
                <tr>
                  <th v-for="(C,c) in ['软件名称','版本','大小','更新时间','下载']" :key="$randomKey(c)">{{C}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(A,a) in links" :key="$randomKey(a)">
                  <td v-for="(B,b) in ['name','version','size','update']" :key="$randomKey(b)" v-html="A[B]"></td>
                  <td>
                    <div class="btn-download" @click="handleDownload(A.link)">下载</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </main>
    <page-footer class="footer" />
  </div>
</template>

<script>
import pageFooter from '@/components/Footer'

export default {
  components: {
    pageFooter,
  },
  data() {
    return {
      icons: [
        {
          icon: 'zIcon-platform',
          text: '操作平台稳定'
        },
        {
          icon: 'zIcon-content',
          text: 'VR内容丰富'
        },
        {
          icon: 'zIcon-data',
          text: '专业管理后台'
        },
        {
          icon: 'zIcon-activites',
          text: '活动运营丰富'
        },
      ],

      popupshow: false,

      link: '',
      links: []
    }
  },
  watch: {
   /*  popupshow(val) {
      document.documentElement.style.overflow = val ? 'hidden' : 'unset'
    } */
  },
  mounted() {
    fetch('/download.json')
      .then(res => { return res.json() })
      .then(res => {
        this.link = res.data.client
        this.links = res.data.related
      })
  },
  methods: {
    handleDownload(link) {
      window.open(link)
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  padding-top: 100px;
}
.section {
  width: 100%;
  background-size: cover;
  background-position: center;
  // padding: unset calc((100% - 1080px) / 2);
  box-sizing: border-box;
}
.section.a {
  padding-top: 150px;
  padding-bottom: 150px;
  background-image: url("~@/assets/download/download_bg_1.jpg");
  display: flex;
  align-items: center;
  justify-content: center;

  .img {
    display: block;
  }

  & > .img {
    width: 540px;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .img {
      width: 640px;
      margin-bottom: 50px;
    }
    .bottom {
      display: flex;
      align-items: center;

      .btn {
        font-size: 18px;
        cursor: pointer;
      }
      .btn.a {
        width: 190px;
        height: 44px;
        border-radius: 22px;
        background-image: linear-gradient(0deg, #1db6b6, #19ecec);
        line-height: 44px;
        text-align: center;
        color: #093b3b;
        margin-right: 50px;
      }
      .btn.b {
        color: #fff;
        padding: 10px;
        text-decoration: underline;
      }
    }
  }
}
.section.b {
  padding-top: 100px;
  padding-bottom: 150px;
  background-image: url("~@/assets/download/download_bg_1.jpg");
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-title {
    width: 384px;
    display: block;
    margin-bottom: 150px;
  }

  .icon-box {
    width: 1080px;
    display: flex;
    justify-content: space-between;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      .icon {
        width: 202px;
        height: 175px;
        background-image: url("~@/assets/download/download_icon_border.png");
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .zamerIcons {
          font-size: 88px;
          line-height: 1;
        }
      }
      span {
        font-size: 24px;
        margin-top: 46px;
      }
    }
  }
}

.section.c {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: rgba(#000, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .popup {
    width: 800px;
    height: 400px;
    background-image: url("~@/assets/download/bg_pop_content.png");
    background-size: cover;
    border-radius: 30px;
    border: solid 1px rgba(#fff, 0.3);
    box-shadow: 0 5px 50px rgba(#fff, 0.3);
    position: relative;

    .btn-close {
      width: 38px;
      height: 38px;
      background-image: url("~@/assets/download/btn_pop_close.png");
      background-size: 100% 100%;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .content {
      width: calc(100% - 40px);
      height: calc(100% - 20px - 58px);
      position: absolute;
      left: 20px;
      bottom: 20px;
      background-color: #666;
      /* display: grid;
      grid-template-columns: 45% repeat(4, 1fr);
      grid-template-rows: auto;
      grid-row-gap: 1px; */
      overflow-y: auto;

      /* .title,
      .item {
        background-color: #373737;
        color: #fff;
        line-height: 30px;
        padding: 5px;
        box-sizing: border-box;
        font-size: 14px;
        height: 30px;
      } */
      table {
        color: #fff;
        font-size: 14px;
        border-spacing: 0 1px;
        thead {
          tr {
            background-color: #373737;
            th {
              padding: 5px;
              box-sizing: border-box;
            }
            th:nth-child(1) {
              width: 50%;
              text-align: left;
              height: 30px;
            }
            th:nth-child(1) ~ th {
              width: calc(50% / 4);
            }
          }
        }
        tbody {
          tr {
            background-color: #373737;

            td {
              padding: 5px;
              box-sizing: border-box;

              .btn-download {
                font-size: 12px;
                padding: 10px;
                background-color: #0edee3;
                border-radius: 5px;
                cursor: pointer;
                color: #000;
              }
            }
            td:not(:nth-child(1)) {
              text-align: center;
            }
            td {
              height: 50px;
            }
          }
        }
      }
    }
  }
}

.down-table {
  width: 100%;
}
</style>
